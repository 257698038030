import { type FC } from "react";

import "./styles.scss";
import { Footer } from "../../components/Footer";

export const DataDeletion: FC = () => {
  return (
    <>
      <div className="page-dataDeletion">
        <h1>How to remove your data</h1>

        <p>
          Verdibox uses a facebook login app to authenticate our users. Verdibox
          does not save your personal data in our server.
        </p>

        <p>
          According to Facebook policy, we have to provide User Data Deletion
          Callback URL or Data Deletion Instructions URL.
        </p>
        <p>
          If you want to delete your activities for Verdibox'S facebook login
          app, you can remove your information by following these steps:
        </p>
        <ul role="list">
          <li>Go to your Facebook Account's “Settings &amp; Privacy”.</li>
          <li>Click “Settings”</li>
          <li>Then click “Security and Login”.</li>
          <li>
            Look for “Apps and Websites” and you will see all of the apps and
            websites you have linked with your Facebook account.
          </li>
          <li>Search and Click “Verdibox” in the search bar.</li>
          <li>Scroll and click “Remove”.</li>
        </ul>
      </div>
      <Footer />
    </>
  );
};
