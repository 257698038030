import * as Json from "./reader/json";
import ls from "localstorage-slim";

ls.config.encrypt = true;

const prefix = "vb-";

export const getFromLocalStorage = (key: string): unknown | undefined => {
  try {
    const value = ls.get(prefix + key);

    return value ? Json.read(value) : undefined;
  } catch (e) {
    return undefined;
  }
};

export const setToLocalStorage = (key: string, value: unknown): void => {
  ls.set(prefix + key, JSON.stringify(value));
};
