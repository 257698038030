import type { FC } from "react";
import { Route, Routes } from "react-router-dom";

import { Sidebar } from "./components/Sidebar";
import { Fallback } from "./Fallback";
import { PlaylistAdminContent } from "./Playlist";
import { Users } from "./Users";

import "./styles.scss";

export const SIDEBAR_WIDTH = 250;

export const AdminPage: FC = () => {
  return (
    // this code was taken from MUI docs
    <div className="page-admin">
      <Sidebar />
      <div
        className="page-admin__content"
        style={{ width: `calc(100% - ${SIDEBAR_WIDTH}px)` }}
      >
        <Routes>
          <Route index element={<PlaylistAdminContent />} />
          <Route path="/fallback" element={<Fallback />} />
          <Route path="/users" element={<Users />} />

          {/* <Route path="/loguot" element={<div>Logout</div>} /> */}
        </Routes>
      </div>
    </div>
  );
};
