import type { FC } from "react";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router";
import { createSignalRContext } from "react-signalr";
import { toast } from "react-toastify";
import { API_URL, PlaylistService } from "../api";
import type { SearchVideoResult } from "../api/generated/data-contracts";
import { logout } from "../redux/slices/auth";
import { upcomingSongsSelector } from "../redux/slices/songs";
import { useTypedSelector } from "../redux/store";
import {
  add as addVotedSong,
  set as setVotedSongs
} from "../redux/slices/votedSongs";

const signalRToken = "";

export interface PublicOutletContext {
  addItemToPlaylist: (songResult: SearchVideoResult) => Promise<void>;
  handleVote: (id: string, value: number) => Promise<void>;
  upcomingSongs: ReturnType<typeof upcomingSongsSelector>;
}

export const SignalRContext = createSignalRContext();

export const EventsProvider: FC = () => {
  const dispatch = useDispatch();
  const upcomingSongs = useTypedSelector(upcomingSongsSelector);
  const userAuth = useTypedSelector((state) => state.authUser.value);

  const userJwt = useTypedSelector((state) => state.auth.value);

  const _votedSongs = useTypedSelector((state) => state.votedSongs.value);

  const _upcomingSongs = useMemo(
    () =>
      upcomingSongs.map((song) => ({
        ...song,
        voted: _votedSongs.includes(song.id)
      })),
    [_votedSongs, upcomingSongs]
  );

  const addItemToPlaylist = useCallback(
    async (songResult: SearchVideoResult): Promise<void> => {
      await PlaylistService.addvideoCreate(
        {
          youtubeId: songResult.youtubeId,
          title: songResult.title,
          thumbnail: songResult.thumbnail
        },
        {
          headers: {
            Authorization: `Bearer ${userAuth || userJwt}`
          }
        }
      )
        .then((res) => {
          if (res.status === 200) {
            dispatch(addVotedSong(res.data.id));
            toast.success("Successfully added!");
          } else {
            toast.error("Something went wrong, please try again.");
          }
        })
        .catch((res) => {
          if (res.response?.status === 400) {
            for (const property in res.response?.data.errors) {
              res.response?.data.errors[property].forEach((eMessage: string) =>
                toast.error(eMessage)
              );
            }
          } else if (res.response?.status === 401) {
            dispatch(logout());
          } else {
            toast.error("Something went wrong, please try again.");
          }
        });
    },
    [dispatch, userJwt, userAuth]
  );

  const updateVoteData = useCallback(
    (id: string, value: number): void => {
      dispatch(
        setVotedSongs(
          value > 0
            ? [..._votedSongs, id]
            : _votedSongs.filter((itemId) => itemId !== id)
        )
      );
    },
    [dispatch, _votedSongs]
  );

  const handleVote = useCallback(
    async (id: string, value: number): Promise<void> =>
      new Promise<void>((resolve) => {
        PlaylistService.updatevideovotesPartialUpdate(
          { id, votes: value },
          {
            headers: {
              Authorization: `Bearer ${userAuth || userJwt}`
            }
          }
        )
          .then((res) => {
            if (res.status === 200) {
              updateVoteData(id, value);
            } else if (res.status === 204) {
              toast.success("Song deleted successfully.");
            } else {
              toast.error("Something went wrong, please try again.");
            }
          })
          .catch((res) => {
            if (res.response?.status === 400) {
              // TODO fix
              toast.error(res.response?.data.errors.votes[0]);
            } else if (res.response?.status === 401) {
              dispatch(logout());
            } else {
              toast.error("Something went wrong, please try again.");
            }
          })
          .finally(() => {
            resolve();
          });
      }),
    [dispatch, updateVoteData, userJwt, userAuth]
  );

  return (
    <SignalRContext.Provider
      // connectEnabled={!!token}
      accessTokenFactory={() => signalRToken}
      dependencies={[signalRToken]}
      url={`${API_URL}/playlist`}
      // onOpen={(a) => {
      // console.log("onOpen", a);
      // }}
      // onClosed={(a) => {
      //   console.log("onClosed", a);
      // }}
      onReconnect={(a) => {
        toast.success("Reconnected!", {
          toastId: "reconnected"
        });

        // TODO: get all songs again
        // TODO: rerender tvmode
        // youtube player does not rerender if same youtube id is passed

        console.log("onReconnect", a);
      }}
      onError={async (a) =>
        new Promise<void>((resolve) => {
          console.log("onError", a);

          if (
            a?.message ===
            "Server timeout elapsed without receiving a message from the server."
          ) {
            toast.error("Connection lost, trying to reconnect...", {
              toastId: "reconnecting"
            });
          }
        })
      }
      onBeforeClose={(a) => {
        console.log("onBeforeClose", a);
      }}
    >
      <Outlet
        context={{
          addItemToPlaylist,
          handleVote,
          upcomingSongs: _upcomingSongs
        }}
      />
    </SignalRContext.Provider>
  );
};
