import { useState, useEffect, useRef, useCallback } from "react";
import type { FC } from "react";
import { CSSTransition } from "react-transition-group";
import YouTube from "react-youtube";
import { toast } from "react-toastify";
import type { AxiosResponse } from "axios";
import { useDispatch } from "react-redux";
import type { YouTubePlayer, YouTubeEvent, YouTubeProps } from "react-youtube";
import PlayerStates from "youtube-player/dist/constants/PlayerStates";

import { logout } from "../../redux/slices/auth";
import {
  type PublicOutletContext,
  SignalRContext
} from "../../providers/EventsProvider";
import { PrivateSignalRContext } from "../../router/PrivateRoutes";
import { useTypedSelector } from "../../redux/store";
import { PlaylistService } from "../../api";
import {
  type FallbackVideoPaginatedResult,
  type Video
} from "../../api/generated/data-contracts";
import { getAllFallbackSongs, getAllSongs } from "../../utils/getAll";

import { FullScreenLogo } from "./components/FullScreenLogo";
import { HelperButtons } from "./components/HelperButtons";

import "./styles.scss";
import { useOutletContext } from "react-router-dom";

const opts: YouTubeProps["opts"] = {
  playerVars: { autoplay: 1 }
};

export const TvModeV2: FC = () => {
  const dispatch = useDispatch();

  const userJwt = useTypedSelector((state) => state.auth.value);

  const rewriteSent = useRef<number>(0);
  const positionInFallback = useRef(0);

  const [displayOverlay, setDisplayOverlay] = useState(true);
  const [isRewritten, setIsRewritten] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [currentSong, setCurrentSong] = useState<Video>();
  const [needPermission, setNeedPermission] = useState(false);

  const { addItemToPlaylist } = useOutletContext<PublicOutletContext>();

  const youtubeRef = useRef<YouTube>(null);

  useEffect(() => {
    setTimeout(() => {
      if (youtubeRef.current?.internalPlayer) {
        youtubeRef.current.internalPlayer.getPlayerState().then((res) => {
          if (res === PlayerStates.UNSTARTED) {
            setNeedPermission(true);
          }
        });
      }
    }, 10000);
  }, []);

  const getNextSongFallback = useCallback((): void => {
    const handleSet = (
      res: AxiosResponse<FallbackVideoPaginatedResult, any>
    ): void => {
      if (res?.data?.results) {
        if (positionInFallback.current > res.data.results.length) {
          positionInFallback.current = 0;
        }

        const fallbackSong = res.data.results[positionInFallback.current];

        if (fallbackSong) {
          addItemToPlaylist({
            youtubeId: fallbackSong.youtubeId,
            title: fallbackSong.title,
            thumbnail: fallbackSong.thumbnail
          });

          positionInFallback.current++;
        } else {
          console.log("no songs in fallback");
          setError("There is no song to play.");
        }
      }
    };
    const afterCatch = (): void => {};
    const handle401 = (): void => {
      dispatch(logout());
    };

    getAllFallbackSongs(userJwt, handleSet, afterCatch, handle401);
  }, [dispatch, userJwt, addItemToPlaylist]);

  const getNextSong = useCallback((): void => {
    getAllSongs(
      (res) => {
        if (Array.isArray(res?.data?.results) && res.data.results.length > 0) {
          const playing = res.data.results.find((s) => s.isPaused !== null);

          if (playing) {
            setCurrentSong(playing);
          } else {
            setCurrentSong(res.data.results[0]);
          }
        } else {
          getNextSongFallback();
        }
      },
      () => {}
    );
  }, [getNextSongFallback]);

  useEffect(() => {
    rewriteSent.current = Math.random();

    PlaylistService.globaljsonbroadcastCreate(
      {
        message: `tvModeRewrite ${rewriteSent.current}`
      },
      {
        headers: {
          Authorization: `Bearer ${userJwt}`
        }
      }
    ).finally(() => {
      getNextSong();
    });
  }, [userJwt, getNextSong]);

  const handleEndVideo = useCallback(async (): Promise<void> => {
    setDisplayOverlay(true);
    setCurrentSong(undefined);

    if (!navigator.onLine) {
      toast.error("Internet connection lost.");

      setError("Can't play the next song, there is no internet connection");
      return;
    }

    setError(null);

    if (currentSong) {
      PlaylistService.deletevideoDelete(
        {
          id: currentSong.id
        },
        {
          headers: {
            Authorization: `Bearer ${userJwt}`
          }
        }
      )
        .then(() => {})
        .catch((res) => {
          if (res.response?.status === 401) {
            dispatch(logout());
          } else {
            toast.error("Something went wrong, please try again");
          }
        })
        .finally(() => {
          getNextSong();
        });
    }
  }, [dispatch, currentSong, userJwt, getNextSong]);

  const reconnectedHandler = useCallback((): void => {
    if (currentSong && youtubeRef.current?.internalPlayer) {
      Promise.all([
        youtubeRef.current.internalPlayer.getPlayerState(),
        youtubeRef.current.internalPlayer.getCurrentTime()
      ]).then((values) => {
        if (values[0] === 0) {
          setDisplayOverlay(true);
          handleEndVideo();
        } else {
          // TODO: update timing (backend)
          console.log("update currentTime", values[1]);
        }
      });
    } else {
      document.location.reload();
    }
  }, [currentSong, youtubeRef, handleEndVideo]);

  useEffect(() => {
    window.addEventListener("online", reconnectedHandler);

    return () => {
      window.removeEventListener("online", reconnectedHandler);
    };
  }, [reconnectedHandler]);

  const handlePlayVideo = useCallback(
    (target: YouTubePlayer): void => {
      setTimeout(() => {
        setDisplayOverlay(false);
      }, 600);

      const duration = target.getDuration() as unknown as number;

      if (currentSong?.isPaused === null) {
        PlaylistService.playvideoPartialUpdate(
          {
            // eslint-disable-next-line
            id: currentSong!.id,
            duration,
            lastPausePlayTime: new Date().toISOString()
          },
          {
            headers: {
              Authorization: `Bearer ${userJwt}`
            }
          }
        )
          .then(() => {
            getNextSong();
          })
          .catch((res) => {
            if (res.response?.status === 401) {
              dispatch(logout());
            }
          });
      } else {
        youtubeRef.current?.internalPlayer
          ?.getCurrentTime()
          .then((currentTime) => {
            PlaylistService.updatevideostatusPartialUpdate(
              {
                // eslint-disable-next-line
                id: currentSong!.id,
                isPaused: false,
                lastPausePlayDuration: currentTime,
                lastPausePlayTime: new Date().toISOString()
              },
              {
                headers: {
                  Authorization: `Bearer ${userJwt}`
                }
              }
            );
          });
      }
    },
    [currentSong, userJwt, dispatch, getNextSong]
  );

  SignalRContext.useSignalREffect(
    "SendNewVideo",
    () => {
      if (!currentSong) {
        getNextSong();
      }
    },
    [dispatch, currentSong]
  );

  PrivateSignalRContext.useSignalREffect(
    "SkipVideo",
    () => {
      setDisplayOverlay(true);

      setTimeout(() => {
        getNextSong();
      }, 600);
    },
    [dispatch, currentSong]
  );

  PrivateSignalRContext.useSignalREffect(
    "AdminUpdateVideoStatus",
    (song: { isPaused: boolean }) => {
      if (currentSong) {
        youtubeRef.current?.internalPlayer
          ?.getCurrentTime()
          .then((currentTime) => {
            PlaylistService.updatevideostatusPartialUpdate(
              {
                id: currentSong.id,
                isPaused: song.isPaused,
                lastPausePlayDuration: currentTime,
                lastPausePlayTime: new Date().toISOString()
              },
              {
                headers: {
                  Authorization: `Bearer ${userJwt}`
                }
              }
            )
              .then(() => {})
              .catch((res) => {
                if (res.response?.status === 401) {
                  dispatch(logout());
                }
              })
              .finally(() => {
                if (youtubeRef.current) {
                  if (song.isPaused) {
                    youtubeRef.current.internalPlayer?.pauseVideo();
                  } else {
                    youtubeRef.current.internalPlayer?.playVideo();
                  }
                }
              });
          });
      } else {
        // TODO: aici putem sa mutam cantecul acolo de unde s-a oprit
        // dar nu cred ca trebuie
      }
    },
    [dispatch, currentSong, userJwt]
  );

  PrivateSignalRContext.useSignalREffect(
    "BroadcastMessage",
    (message) => {
      const [name, uuid] = message.split(" ");

      if (name === "tvModeRewrite") {
        if (uuid !== String(rewriteSent.current)) {
          setIsRewritten(true);
        }
      }
    },
    [rewriteSent]
  );

  const getErrorMessage = useCallback(
    (error: string | null): string | null => {
      return isRewritten
        ? "TV Mode is rewritten, please refresh the page"
        : needPermission
        ? "Browser permission needed"
        : error;
    },
    [isRewritten, needPermission]
  );

  return (
    <div className="tv-mode">
      {/* TODO: poate aratam overlay daca video e paused */}
      <CSSTransition
        in={displayOverlay || isRewritten}
        timeout={600}
        classNames="fullscreen-logo"
        unmountOnExit
      >
        <FullScreenLogo error={getErrorMessage(error)} />
      </CSSTransition>
      {!isRewritten && currentSong && (
        <YouTube
          ref={youtubeRef}
          videoId={currentSong.youtubeId}
          className="tv-mode__video"
          iframeClassName="tv-mode__video-iframe"
          opts={opts}
          // maybe change this with on ready
          // because onPlay is executed on every play/pause on iframe

          onPlay={(e) => {
            handlePlayVideo(e.target);
          }}
          onEnd={() => {
            handleEndVideo();
          }}
          onError={(e: YouTubeEvent<number>) => {
            // TODO: on any error, should retry and then go to next video
            // example, no internet connection
            console.log("youtube api error", e);

            // https://developers.google.com/youtube/iframe_api_reference#Events -> onError
            // 2 - request contains an invalid parameter value
            // 5 - requested content cannot be played in an HTML5 player
            // 100 - video requested was not found
            // 101 - owner of the requested video does not allow it to be played in embedded players
            // 105 - same as 101. It's just a 101 error in disguise!

            handleEndVideo();
          }}
        />
      )}
      <HelperButtons />
    </div>
  );
};
