import { useState } from "react";
import type { FC } from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import CircularProgress from "@mui/material/CircularProgress";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { SongItem } from "../../SongItem";
import type { SongResultType } from "../../SongItem";

import "./styles.scss";
import { isNullish } from "../../../utils/value";
import { numformat } from "../../../utils/numberFormatter";

interface Props {
  songResult: SongResultType;
  songViews?: number;
  handleAddClick: () => Promise<void>;
  buttonText: string;
}

export const SongResult: FC<Props> = ({
  songResult,
  songViews,
  handleAddClick,
  buttonText
}) => {
  const [loading, setLoading] = useState(false);

  const handleAdd = (): void => {
    setLoading(true);

    handleAddClick().then(() => {
      setLoading(false);
    });
  };

  const AddButton = (
    <Button
      className="songs-list__item__config__add-button"
      variant="contained"
      size="small"
      startIcon={loading ? undefined : <AddIcon />}
      onClick={handleAdd}
    >
      {loading ? <CircularProgress size={20} /> : buttonText}
    </Button>
  );

  return (
    <SongItem
      song={{
        ...songResult,
        title: songResult.title ?? ""
      }}
      ConfigComponent={
        <>
          {!isNullish(songViews) && (
            <div className="songs-list__item-config__views">
              <div className="songs-list__item-config__views-number">
                {numformat(songViews)}
              </div>
              <VisibilityIcon />
            </div>
          )}

          {AddButton}
        </>
      }
    />
  );
};
