import { useEffect, useState, type FC } from "react";
import { DataGrid, type GridColDef } from "@mui/x-data-grid";
import { Pagination } from "@mui/material";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../redux/store";
import { set as setUsersPage } from "../../../redux/slices/usersPage";
import { UserService } from "../../../api";
import { type AxiosResponse } from "axios";
import { type GetUserResultPaginatedResult } from "../../../api/generated/data-contracts";
import { set as setUsers } from "../../../redux/slices/users";

const columns: GridColDef[] = [
  {
    field: "userId",
    headerName: "Login with",
    width: 100,
    sortable: false,
    filterable: false,
    renderCell(params) {
      const isGoogle = params.value.includes("google");

      if (isGoogle) {
        return "Google";
      }

      const isFacebook = params.value.includes("facebook");
      if (isFacebook) {
        return "Facebook";
      }

      const isAuth0 = params.value.includes("auth0");
      if (isAuth0) {
        return "Auth0";
      }

      return "Unknown";
    }
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    minWidth: 230,
    resizable: true,
    sortable: false,
    filterable: false
  },
  {
    field: "firstName",
    headerName: "First name",
    flex: 1,
    minWidth: 90,
    sortable: false,
    filterable: false
  },
  {
    field: "lastName",
    headerName: "Last name",
    flex: 1,
    minWidth: 90,
    sortable: false,
    filterable: false
  },
  {
    field: "loginsCount",
    headerName: "Login count",
    width: 120,
    sortable: false,
    filterable: false
  },
  {
    field: "createdAt",
    headerName: "Registered at",
    flex: 1,
    minWidth: 160,
    sortable: false,
    filterable: false,
    renderCell(params) {
      return new Date(params.value).toLocaleString("it-IT");
    }
  },
  {
    field: "lastLogin",
    headerName: "Last login",
    flex: 1,
    minWidth: 160,

    sortable: false,
    filterable: false,
    renderCell(params) {
      return new Date(params.value).toLocaleString("it-IT");
    }
  }
];

export const Users: FC = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const users = useTypedSelector((state) => state.users.value);
  const usersPage = useTypedSelector((state) => state.usersPage.value);

  const userJwt = useTypedSelector((state) => state.auth.value);

  const _users = users.list.map((user) => ({
    userId: user.userId,
    email: user.email,
    userName: user.userName,
    firstName: user.firstName,
    lastName: user.lastName,
    createdAt: user.createdAt,
    lastLogin: user.lastLogin,
    loginsCount: user.loginsCount
  }));

  useEffect(() => {
    setIsLoading(true);
    UserService.userList(
      {
        pageNo: usersPage - 1,
        perPage: 50
      },
      {
        headers: {
          Authorization: `Bearer ${userJwt}`
        }
      }
    )
      .then((res: AxiosResponse<GetUserResultPaginatedResult>) => {
        dispatch(
          setUsers({
            list: res.data.results ?? [],
            pages: Math.ceil((res.data.totalMatchingRecords ?? 50) / 50)
          })
        );
      })
      .catch(() => {
        setUsers({
          list: [],
          pages: 1
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [userJwt, dispatch, usersPage]);

  return (
    <div style={{ background: "white", height: "80vh" }}>
      <DataGrid
        className="table-grid"
        rows={_users}
        density="compact"
        loading={isLoading}
        columns={columns}
        getRowId={(row) => row.userId}
        disableRowSelectionOnClick
        slots={{
          pagination: () => (
            <Pagination
              page={usersPage}
              count={users.pages}
              onChange={(_, v) => {
                dispatch(setUsersPage(v));
              }}
            />
          )
        }}
      />
    </div>
  );
};
