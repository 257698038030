import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface AuthState {
  value: string;
}

const initialState: AuthState = {
  value: ""
};

export const authSlice = createSlice({
  name: "authUser",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    }
  }
});

export const { set } = authSlice.actions;

export const authUserReducer = authSlice.reducer;
