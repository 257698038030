export const numformat = (n: number): string => {
  if (n < 1e3) {
    return String(n);
  }
  if (n < 1e6) {
    return (n / 1e3).toFixed(1) + "K";
  }
  if (n < 1e9) {
    return (n / 1e6).toFixed(1) + "M";
  }
  if (n < 1e12) {
    return (n / 1e9).toFixed(1) + "B";
  }

  return (n / 1e12).toFixed(1) + "T";
};
