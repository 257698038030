import { useCallback } from "react";
import type { FC } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";

import { SIDEBAR_WIDTH } from "../..";
import { useFullScreenHandler } from "../../../../hooks/useFullScreenHandler";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

import "./styles.scss";
import { logout } from "../../../../redux/slices/auth";
import { useTypedSelector } from "../../../../redux/store";
import { setKey } from "../../../../redux/slices/config";

const renderItem = (
  {
    className,
    title,
    icon,
    onClick
  }: {
    className: string;
    title: string;
    icon: () => JSX.Element;
    onClick: () => void;
  },
  index: number
): JSX.Element => {
  return (
    <ListItem disablePadding className={className} key={index}>
      <ListItemButton onClick={onClick}>
        <ListItemIcon>{icon()}</ListItemIcon>
        <ListItemText primary={title} />
      </ListItemButton>
    </ListItem>
  );
};

export const Sidebar: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isSidebarMobileOpen = useTypedSelector(
    (state) => state.config.isSidebarMobileOpen
  );

  const handleSidebarMobileToggle = useCallback((): void => {
    dispatch(
      setKey({ key: "isSidebarMobileOpen", value: !isSidebarMobileOpen })
    );
  }, [dispatch, isSidebarMobileOpen]);

  const { enterFullScreen } = useFullScreenHandler();

  const sidebarItems = (
    <div className="sidebar-items">
      <img
        className="sidebar-items__logo"
        src="./assets/verdibox-dark.svg"
        alt="logo"
      />
      <Divider />
      <List>
        {[
          {
            className: classNames("sidebar-item", {
              "sidebar-item-active": location.pathname === "/admin"
            }),
            title: "Playlist",
            icon: () => <SubscriptionsIcon />,
            onClick: () => {
              handleSidebarMobileToggle();
              navigate("/admin");
            }
          },
          {
            className: classNames("sidebar-item", {
              "sidebar-item-active": location.pathname === "/admin/fallback"
            }),
            title: "Fallback",
            icon: () => <VideoSettingsIcon />,
            onClick: () => {
              handleSidebarMobileToggle();
              navigate("/admin/fallback");
            }
          },
          {
            className: classNames("sidebar-item", {
              "sidebar-item-active": location.pathname === "/admin/users"
            }),
            title: "Users",
            icon: () => <PeopleAltIcon />,
            onClick: () => {
              handleSidebarMobileToggle();
              navigate("/admin/users");
            }
          },
          ...(!isMobile
            ? [
                {
                  className: classNames("sidebar-item"),
                  title: "TV Mode",
                  icon: () => <OndemandVideoIcon />,
                  onClick: () => {
                    enterFullScreen();

                    navigate("/player");
                  }
                }
              ]
            : [])
        ].map((item, index) => renderItem(item, index))}
      </List>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              dispatch(logout());
            }}
          >
            <ListItemText primary="Log out" />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  return (
    // this code was taken from MUI docs
    <Box
      component="nav"
      sx={{ width: { sm: SIDEBAR_WIDTH }, flexShrink: { sm: 0 } }}
      className="sidebar"
    >
      <Drawer
        className="sidebar-drawer"
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: SIDEBAR_WIDTH
          }
        }}
        open
      >
        {sidebarItems}
      </Drawer>

      <Drawer
        variant="temporary"
        open={isSidebarMobileOpen}
        onClose={handleSidebarMobileToggle}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: SIDEBAR_WIDTH
          }
        }}
      >
        {sidebarItems}
      </Drawer>
    </Box>
  );
};
