import type { FC } from "react";
import { useNavigate } from "react-router-dom";

import { useFullScreenHandler } from "../../../../hooks/useFullScreenHandler";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import FullscreenIcon from "@mui/icons-material/Fullscreen";

import "./styles.scss";

export const HelperButtons: FC = () => {
  const navigate = useNavigate();

  const { enterFullScreen, exitFullScreen, inFullScreen } =
    useFullScreenHandler();

  return (
    <div className="tv-mode__buttons">
      <div
        className="tv-mode__buttons-back"
        onClick={() => {
          exitFullScreen();
          navigate("/admin");
        }}
      >
        <ArrowBackIcon /> BACK
      </div>

      <div
        className="tv-mode__buttons-fullscreen"
        onClick={inFullScreen ? exitFullScreen : enterFullScreen}
      >
        {inFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
      </div>
    </div>
  );
};
