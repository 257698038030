import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { type GetUserResult } from "../../api/generated/data-contracts";

interface ValueState {
  list: GetUserResult[];
  pages: number;
}

export interface RequestsState {
  value: ValueState;
}

const initialState: RequestsState = {
  value: { list: [], pages: 1 }
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<ValueState>) => {
      state.value = action.payload;
    },
    setData: (state, action: PayloadAction<GetUserResult[]>) => {
      state.value.list = action.payload;
    },
    setPages: (state, action: PayloadAction<number>) => {
      state.value.pages = action.payload;
    }
  }
});

export const { set, setData, setPages } = usersSlice.actions;

export const usersReducer = usersSlice.reducer;
