import { useCallback, useState } from "react";
import type { FC } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { CircularProgress } from "@mui/material";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import SkipNextRoundedIcon from "@mui/icons-material/SkipNextRounded";
import PauseIcon from "@mui/icons-material/Pause";

import type { Video } from "../../../../api/generated/data-contracts";
import { SongLikes } from "../../../../components/SongLikes";
import { PlaylistService } from "../../../../api";
import { setIsPausePlaying } from "../../../../redux/slices/songs";
import { logout } from "../../../../redux/slices/auth";
import { useTypedSelector } from "../../../../redux/store";

interface Props {
  song: Video;
}

export const Controls: FC<Props> = ({ song }) => {
  const { votes, isPaused } = song;

  const dispatch = useDispatch();

  const [isSkipping, setIsSkipping] = useState(false);

  const isPausePlaying = useTypedSelector(
    (state) => state.songs.isPausePlaying
  );

  const userJwt = useTypedSelector((state) => state.auth.value);

  const handleSkipVideo = useCallback((): void => {
    setIsSkipping(true);

    PlaylistService.deletevideoDelete(
      {
        id: song.id
      },
      {
        headers: {
          Authorization: `Bearer ${userJwt}`
        }
      }
    )
      .then(() => {
        PlaylistService.skipvideoList({
          headers: {
            Authorization: `Bearer ${userJwt}`
          }
        });
      })
      .catch((res) => {
        if (res.response?.status === 401) {
          dispatch(logout());
        } else {
          toast.error("Something went wrong, please try again");
        }
      })
      .finally(() => {
        setIsSkipping(false);
      });
  }, [dispatch, userJwt, song, setIsSkipping]);

  const handlePlayPause = useCallback((): void => {
    dispatch(setIsPausePlaying(true));

    PlaylistService.adminupdatevideostatusCreate(
      {
        isPaused: !isPaused
      },
      {
        headers: {
          Authorization: `Bearer ${userJwt}`
        }
      }
    ).catch((res) => {
      if (res.response?.status === 401) {
        dispatch(logout());
      } else {
        toast.error("Error skipping video");
      }
    });
  }, [userJwt, dispatch, isPaused]);

  return (
    <div className="current-song__controls">
      <SongLikes likes={votes} />

      <div className="current-song__controls-play" onClick={handlePlayPause}>
        {/* TODO: add a timeout */}
        {isPausePlaying ? (
          <CircularProgress style={{ color: "white" }} size={50} />
        ) : isPaused ? (
          <PlayArrowRoundedIcon />
        ) : (
          <PauseIcon />
        )}
      </div>

      <div className="current-song__controls-next" onClick={handleSkipVideo}>
        {isSkipping ? <CircularProgress /> : <SkipNextRoundedIcon />}
      </div>
    </div>
  );
};
