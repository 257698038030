import React, { useRef, useState } from "react";
import type { FC } from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

import { UserService } from "../../api";

import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";

import "./styles.scss";
import { useTypedSelector } from "../../redux/store";
import { useDispatch } from "react-redux";
import { login } from "../../redux/slices/auth";

export const LoginPage: FC = () => {
  const dispatch = useDispatch();

  const emailRef = useRef<HTMLInputElement>(null);
  const passRef = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState(false);
  const userJwt = useTypedSelector((state) => state.auth.value);

  const handleLogin = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (loading) {
      return;
    }

    if (emailRef.current?.value && passRef.current?.value) {
      setLoading(true);

      UserService.userCreate({
        username: emailRef.current.value,
        password: passRef.current.value
      })
        .then((res) => {
          if (res.status === 200) {
            dispatch(login(res.data));
          } else {
            toast.error("Username or password incorrect.");
          }
        })
        .catch((res) => {
          if (res.response?.status === 401) {
            toast.error("Username or password incorrect.");
          } else {
            toast.error("Something went wrong, please try again.");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      toast.error("Please fill all the fields.");
    }
  };

  if (userJwt) {
    return <Navigate to="/admin" />;
  }

  return (
    <div className="page-login">
      <div className="modal">
        <img
          className="modal-logo"
          src="./assets/verdibox-dark.svg"
          alt="logo"
        />
        <span className="modal-title">SIGN IN</span>

        <form className="modal-form" onSubmit={handleLogin}>
          <TextField
            name="username"
            type="text"
            label="Username"
            variant="standard"
            margin="dense"
            inputRef={emailRef}
          />
          <TextField
            name="password"
            type="password"
            label="Password"
            variant="standard"
            margin="dense"
            inputRef={passRef}
          />
          <button type="submit" disabled={loading}>
            {loading ? (
              <CircularProgress style={{ color: "white" }} size={16} />
            ) : (
              "LOGIN"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};
