import { Innertube, UniversalCache } from "youtubei.js/bundle/browser";
import { PROXY_URL } from "../../api";

export const youtubeClient = Innertube.create({
  generate_session_locally: true,
  fetch: async (input: RequestInfo | URL, init?: RequestInit) => {
    // url
    const url =
      typeof input === "string"
        ? new URL(input)
        : input instanceof URL
        ? input
        : new URL(input.url);

    // transform the url for use with our proxy
    url.searchParams.set("__host", url.host);
    url.host = PROXY_URL;
    url.protocol = "https";

    const headers = init?.headers
      ? new Headers(init.headers)
      : input instanceof Request
      ? input.headers
      : new Headers();

    // now serialize the headers
    url.searchParams.set("__headers", JSON.stringify([...headers]));

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    input.duplex = "half";

    // copy over the request
    const request = new Request(
      url,
      input instanceof Request ? input : undefined
    );

    headers.delete("user-agent");

    // fetch the url
    return fetch(
      request,
      init
        ? {
            ...init,
            headers
          }
        : {
            headers
          }
    );
  },
  cache: new UniversalCache(false)
});
