/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  GetUserResultPaginatedResult,
  LogInRequest,
  ProblemDetails
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class User<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags User
   * @name UserCreate
   * @request POST:/user
   * @secure
   */
  userCreate = (data: LogInRequest, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/user`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags User
   * @name UserList
   * @request GET:/user
   * @secure
   */
  userList = (
    query?: {
      /**
       * @format int32
       * @min 0
       * @max 2147483647
       * @default 0
       */
      pageNo?: number;
      /**
       * @format int32
       * @min 1
       * @max 50
       * @default 50
       */
      perPage?: number;
    },
    params: RequestParams = {}
  ) =>
    this.request<GetUserResultPaginatedResult, string | ProblemDetails>({
      path: `/user`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
}
