import { Playlist } from "./generated/Playlist";
import { User } from "./generated/User";
import { Youtube } from "./generated/Youtube";
import { Api } from "./generated/Api";
import { Fallbackplaylist } from "./generated/Fallbackplaylist";
import { Music } from "./generated/Music";

const isProd = (): boolean => true;

const PROXY_URL_DEV = "localhost:8080";
const PROXY_URL_PROD = "proxy.verdibox.it";
export const PROXY_URL = isProd() ? PROXY_URL_PROD : PROXY_URL_DEV;

const API_URL_DEV = "http://cmdevs-001-site3.htempurl.com";
const API_URL_PROD = "https://api.verdibox.it";
export const API_URL = isProd() ? API_URL_PROD : API_URL_DEV;

export const UserService = new User({ baseURL: API_URL });
export const PlaylistService = new Playlist({ baseURL: API_URL });
export const YouTubeService = new Youtube({ baseURL: API_URL });
export const FallbackPlaylistService = new Fallbackplaylist({
  baseURL: API_URL
});
export const ApiService = new Api({ baseURL: API_URL });
export const MusicService = new Music({ baseURL: API_URL });
