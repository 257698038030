import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import type { Video } from "../../api/generated/data-contracts";
import type { AppState } from "../store";

export interface SongsState {
  value: Video[];
  loading: boolean;
  isPausePlaying: boolean;
}

const initialState: SongsState = {
  value: [],
  loading: true,
  isPausePlaying: false
};

export const songsSlice = createSlice({
  name: "songs",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setIsPausePlaying: (state, action: PayloadAction<boolean>) => {
      state.isPausePlaying = action.payload;
    },
    set: (state, action: PayloadAction<Video[]>) => {
      state.value = action.payload;
      state.loading = false;
      state.isPausePlaying = false;
    },
    add: (state, action: PayloadAction<Video>) => {
      state.value = [...state.value, action.payload];
    },
    update: (state, action: PayloadAction<Video>) => {
      state.value = state.value.map((s) =>
        s.id === action.payload.id ? action.payload : s
      );

      state.isPausePlaying = false;
    },

    remove: (state, action: PayloadAction<string>) => {
      state.value = state.value.filter((song) => song.id !== action.payload);
    },
    updateVotes: (
      state,
      action: PayloadAction<{ id: string; votes: number }>
    ) => {
      state.value = state.value.map((s) =>
        s.id === action.payload.id ? { ...s, votes: action.payload.votes } : s
      );
    },
    nextSong: (
      state,
      action: PayloadAction<{ oldId: string; nextVid: Video }>
    ) => {
      state.value = state.value
        .filter((song) => song.id !== action.payload.oldId)
        .map((s) =>
          s.id === action.payload.nextVid.id ? action.payload.nextVid : s
        );
    }
  }
});

const getSongs = (state: AppState): SongsState => state.songs;

export const allSongs = createSelector([getSongs], (songs) => songs.value);
export const currentSongSelector = createSelector([getSongs], (songs) =>
  songs.value.find((s) => s.isPaused !== null)
);

export const upcomingSongsSelector = createSelector([getSongs], (songs) =>
  songs.value
    .filter((s) => s.isPaused === null)

    .sort((a, b) => {
      if (a.votes < b.votes) {
        return 1;
      }

      if (a.votes > b.votes) {
        return -1;
      }

      return 0;
    })
);

export const {
  setLoading,
  set,
  add,
  remove,
  update,
  updateVotes,
  setIsPausePlaying,
  nextSong
} = songsSlice.actions;

export const songsReducer = songsSlice.reducer;
