import type { FC } from "react";

import "./styles.scss";

interface Props {
  error: string | null;
}

export const FullScreenLogo: FC<Props> = ({ error }) => {
  return (
    <div className="full-screen-logo">
      <img src="./assets/verdibox.svg" alt="logo" />
      {error ? <div className="full-screen-logo-error">{error}</div> : null}
    </div>
  );
};
