import { type FC } from "react";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Typography } from "@mui/material";

import { currentSongSelector } from "../../redux/slices/songs";
import { useTypedSelector } from "../../redux/store";
import { SearchBar } from "../../components/SearchBar";
import { SongsList } from "../Admin/components/SongsList";
import type { PublicOutletContext } from "../../providers/EventsProvider";

import "./styles.scss";
import { Footer } from "../../components/Footer";

export const VotePage: FC = () => {
  const currentSong = useSelector(currentSongSelector);
  const loadingSongs = useTypedSelector((state) => state.songs.loading);

  const { addItemToPlaylist, handleVote, upcomingSongs } =
    useOutletContext<PublicOutletContext>();
  const { logout, user, loginWithRedirect, isAuthenticated } = useAuth0();

  const _handleVote = async (id: string, value: number): Promise<void> =>
    new Promise((resolve) => {
      if (isAuthenticated) {
        handleVote(id, value).finally(() => {
          resolve();
        });
      } else {
        resolve();

        loginWithRedirect();
      }
    });

  return (
    <div className="page-vote">
      <SearchBar handleAdd={addItemToPlaylist} />

      {!isAuthenticated && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            margin: "15px 10px 30px"
          }}
        >
          <Typography color={"gray"}>
            To vote or add songs, please log in first.
          </Typography>
          <Button
            style={{ color: "#4397ea", borderColor: "#4397ea" }}
            variant="outlined"
            onClick={() => {
              loginWithRedirect();
            }}
          >
            Log in
          </Button>
        </div>
      )}

      {loadingSongs ? (
        <CircularProgress />
      ) : !currentSong && upcomingSongs.length === 0 ? (
        <div className="page-vote__no-items">No song added</div>
      ) : (
        <>
          {currentSong && <SongsList songs={[currentSong]} />}

          <Divider
            style={{
              width: "95%",
              borderColor: "#2d3438",
              margin: "20px 0"
            }}
          />

          <SongsList songs={upcomingSongs} handleVote={_handleVote} />
        </>
      )}

      {isAuthenticated && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            margin: "15px 10px 30px"
          }}
        >
          <Typography color={"white"}>
            Logged in as {user?.family_name ?? ""} {user?.given_name ?? ""}
          </Typography>
          <Button
            style={{ color: "#4397ea", borderColor: "#4397ea" }}
            variant="outlined"
            onClick={() => {
              logout();
            }}
          >
            Logout
          </Button>
        </div>
      )}

      <a href="https://www.instagram.com/verdijesolo/" target="_blank">
        <img
          className="follow-us"
          src="./assets/follow-us-instagram.png"
          loading="lazy"
        />
      </a>

      <Footer />
    </div>
  );
};
