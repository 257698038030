/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { SearchMusicResult } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Music<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Search
   * @name MusicList
   * @request GET:/music
   * @secure
   */
  musicList = (
    query?: {
      input?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<SearchMusicResult[], any>({
      path: `/music`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
}
