import { useRef, useEffect } from "react";
import type { FC } from "react";

import type { Video } from "../../../../api/generated/data-contracts";

import { getDurationInfo } from "./utils";
import { formatDuration } from "../../../../utils/formatTime";

const getProgressBarWidth = (currentDur: number, totalDur: number): number =>
  Math.min(100, Math.max(1, (currentDur * 100) / totalDur));

interface Props {
  song: Video;
}

export const ProgressBar: FC<Props> = ({ song }) => {
  const progressBarRef = useRef<HTMLDivElement>(null);
  const currentTimeRef = useRef<HTMLSpanElement>(null);

  const { totalDuration, currentDuration } = getDurationInfo(song);

  const timeout = useRef<NodeJS.Timeout>();
  const visibleProgressWidth = useRef<number>(currentDuration);

  useEffect(() => {
    if (!song.isPaused) {
      timeout.current = setInterval(() => {
        if (progressBarRef.current && currentTimeRef.current) {
          progressBarRef.current.style.width = `${getProgressBarWidth(
            visibleProgressWidth.current,
            totalDuration
          )}%`;

          currentTimeRef.current.innerText = formatDuration(
            Math.min(totalDuration, visibleProgressWidth.current)
          );

          visibleProgressWidth.current++;
        }
      }, 1000);
    }

    return () => {
      visibleProgressWidth.current = currentDuration;

      clearInterval(timeout.current);
    };
  }, [song, totalDuration, currentDuration]);

  return (
    <div className="current-song__progress-bar">
      <div className="current-song__progress-bar-time-details">
        <span ref={currentTimeRef}>{formatDuration(currentDuration)}</span>
        <span>{formatDuration(totalDuration)}</span>
      </div>

      <div className="current-song__progress-bar-outer">
        <div
          className="current-song__progress-bar-inner"
          ref={progressBarRef}
          style={{
            width: `${getProgressBarWidth(currentDuration, totalDuration)}%`
          }}
        />
      </div>
    </div>
  );
};
