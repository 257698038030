import classNames from "classnames";
import type { FC, ReactNode } from "react";

import { decodeHtmlEntities } from "../../utils/htmlEntities";

import "./styles.scss";

export interface SongResultType {
  youtubeId?: string;
  title: string;
  thumbnail: string;
}
interface Props {
  song: SongResultType;
  className?: string;
  ConfigComponent?: ReactNode;
}

export const SongItem: FC<Props> = ({ song, ConfigComponent, className }) => {
  return (
    <div className={classNames("songs-list__item", className)}>
      <div className="songs-list__item-info">
        <div
          className="songs-list__item-info__thumbnail"
          style={{
            backgroundImage: `url(${song.thumbnail})`
          }}
        ></div>
        {song.youtubeId ? (
          <a
            href={`https://www.youtube.com/watch?v=${song.youtubeId}`}
            target="_blank"
            className="songs-list__item-info__title"
            title={decodeHtmlEntities(song.title)}
          >
            {decodeHtmlEntities(song.title)}
          </a>
        ) : (
          <span>{decodeHtmlEntities(song.title)}</span>
        )}
      </div>
      {ConfigComponent && (
        <div className="songs-list__item-config">{ConfigComponent}</div>
      )}
    </div>
  );
};
