import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import type { Video } from "../../../api/generated/data-contracts";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";

interface Props {
  song: Video;
  handleClose: () => void;
  handleVote: (id: string, value: number) => Promise<void>;
}

export const EditDialog = ({
  song,
  handleClose,
  handleVote
}: Props): JSX.Element => {
  const [newVote, setNewVote] = useState(song.votes);

  const [loading, setLoading] = useState(false);

  const handleSubmit = (): void => {
    const addedVotes = newVote - song.votes;

    if (addedVotes === 0) {
      handleClose();
      return;
    }

    setLoading(true);

    handleVote(song.id, addedVotes).finally(() => {
      setLoading(false);

      handleClose();

      toast.success(`Votes updated.`);
    });
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    const regex = /^[0-9\b]+$/;

    if (e.target.value === "" || regex.test(e.target.value)) {
      setNewVote(Number(e.target.value));
    }
  };

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle>{`Edit votes for ${song.title}`}</DialogTitle>
      <DialogContent>
        <TextField
          size="small"
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          value={newVote}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit} variant="contained" color="success">
              Confirm
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
