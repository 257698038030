import type { FC } from "react";
import { Navigate, Outlet, useOutletContext } from "react-router";
import { useTypedSelector } from "../redux/store";
import { toast } from "react-toastify";
import { createSignalRContext } from "react-signalr";

import type { PublicOutletContext } from "../providers/EventsProvider";
import { API_URL } from "../api";

export const PrivateSignalRContext = createSignalRContext();

export const PrivateRoutes: FC = () => {
  const userJwt = useTypedSelector((state) => state.auth.value);

  const context = useOutletContext<PublicOutletContext>();

  return userJwt ? (
    <PrivateSignalRContext.Provider
      connectEnabled={!!userJwt}
      accessTokenFactory={() => userJwt}
      dependencies={[userJwt]}
      url={`${API_URL}/adminplaylist`}
      onOpen={(a) => {
        console.log("PrivateEventsProvider onOpen", a);
      }}
      onClosed={(a) => {
        console.log("PrivateEventsProvider onClosed", a);
      }}
      onReconnect={(a) => {
        toast.success("Reconnected!", {
          toastId: "reconnected"
        });
        console.log("PrivateEventsProvider onReconnect", a);
      }}
      onError={async (a) =>
        new Promise<void>((resolve) => {
          console.log("PrivateEventsProvider onError", a);

          if (
            a?.message ===
            "Server timeout elapsed without receiving a message from the server."
          ) {
            toast.error("Connection lost, trying to reconnect...", {
              toastId: "reconnecting"
            });
          }
        })
      }
      onBeforeClose={(a) => {
        console.log("PrivateEventsProvider onBeforeClose", a);
      }}
    >
      <Outlet context={context} />
    </PrivateSignalRContext.Provider>
  ) : (
    <Navigate to="/login" />
  );
};
