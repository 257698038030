import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Auth0Provider } from "@auth0/auth0-react";

import { App } from "./App";
import { store } from "./redux/store";

import "./styles.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <HashRouter>
      <ToastContainer pauseOnFocusLoss={false} />
      <Auth0Provider
        domain="verdibox.eu.auth0.com"
        clientId={process.env.REACT_APP_CLIENT_ID ?? ""}
        authorizationParams={{
          redirect_uri: `${"https://verdibox.it/#/vote"}`,
          audience: "api.verdibox.it",
          scope: "openid profile email"
        }}
        cacheLocation="localstorage"
        useRefreshTokens
      >
        <App />
      </Auth0Provider>
    </HashRouter>
  </Provider>
);
