import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface VotedSongsState {
  value: string[];
}

const initialState: VotedSongsState = {
  value: []
};

export const votedSongsSlice = createSlice({
  name: "votedSongs",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<string[]>) => {
      state.value = action.payload;
    },
    add: (state, action: PayloadAction<string>) => {
      state.value.push(action.payload);
    }
  }
});

export const { set, add } = votedSongsSlice.actions;

export const votedSongsReducer = votedSongsSlice.reducer;
