import type { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { FallbackPlaylistService, PlaylistService } from "../api";
import type {
  FallbackVideoPaginatedResult,
  VideoPaginatedResult
} from "../api/generated/data-contracts";

export const getAllSongs = (
  handleSet: (res: AxiosResponse<VideoPaginatedResult, any>) => void,
  afterCatch: () => void
): void => {
  PlaylistService.getallvideosList()
    .then((res) => {
      if (res.status === 200) {
        handleSet(res);
      } else {
        toast.error("Something went wrong, please try again.");
      }
    })
    .catch(() => {
      toast.error("Something went wrong, please try again.");
      afterCatch();
    });
};

export const getAllFallbackSongs = (
  userJwt: string,
  handleSet: (res: AxiosResponse<FallbackVideoPaginatedResult, any>) => void,
  afterCatch: () => void,
  handle401: () => void
): void => {
  FallbackPlaylistService.getallvideosList(
    {},
    {
      headers: {
        Authorization: `Bearer ${userJwt}`
      }
    }
  )
    .then((res) => {
      if (res.status === 200) {
        handleSet(res);
      } else {
        toast.error("Something went wrong, please try again.");
      }
    })
    .catch((res) => {
      if (res.response?.status === 401) {
        handle401();
      } else {
        toast.error("Something went wrong, please try again.");
      }

      afterCatch();
    });
};
