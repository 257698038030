import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import type { FC, ChangeEvent } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import classNames from "classnames";
import { toast } from "react-toastify";
import type CompactVideo from "youtubei.js/dist/src/parser/classes/CompactVideo";
import InputBase from "@mui/material/InputBase";
import CircularProgress from "@mui/material/CircularProgress";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import { ClickAwayListener, Divider } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import type {
  SearchVideoResult,
  SearchMusicResult
} from "../../api/generated/data-contracts";
import { MusicService } from "../../api";
import { setKey } from "../../redux/slices/config";
import { useTypedSelector } from "../../redux/store";

import { SongResult } from "./SongResult";
import { youtubeClient } from "./youtubeClient";

import "./styles.scss";
import { SongItem } from "../SongItem";
import Button from "@mui/material/Button";
import { useAuth0 } from "@auth0/auth0-react";

interface ProxyResult {
  viewCountNumber: number;
  youtubeId: string;
  title: string;
  duration: number;
  thumbnail: string | undefined;
}

interface Props {
  handleAdd: (songResult: SearchVideoResult) => Promise<void>;
}

export const SearchBar: FC<Props> = ({ handleAdd }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isAdminPage = matchPath({ path: "/admin/*" }, location.pathname);

  const auth0 = useAuth0();
  const { loginWithRedirect } = auth0;

  const userAuth = useTypedSelector((state) => state.authUser.value);
  const userJwt = useTypedSelector((state) => state.auth.value);

  const inputRef = useRef<HTMLInputElement>(null);

  const [showClearIcon, setShowClearIcon] = useState(false);
  const [results, setResults] = useState<SearchMusicResult[]>([]);
  const [results2, setResults2] = useState<ProxyResult[]>([]);
  const [resultToShow, setResultToShow] = useState<SearchMusicResult>();

  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState(false);

  const version = useMemo(() => localStorage.getItem("featureFlagSearch"), []);

  const handleFocus = (): void => {
    if (userJwt || userAuth) {
      setFocused(true);
    } else {
      loginWithRedirect();
    }
  };

  const isSidebarMobileOpen = useTypedSelector(
    (state) => state.config.isSidebarMobileOpen
  );

  const handleSidebarMobileToggle = (): void => {
    dispatch(
      setKey({ key: "isSidebarMobileOpen", value: !isSidebarMobileOpen })
    );
  };

  const handleSearch = useCallback(
    async (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): Promise<void> => {
      if (e.target.value.length === 0) {
        setResults([]);
        return;
      }

      setResults2([]);
      setResultToShow(undefined);
      setFocused(true);

      setLoading(true);

      MusicService.musicList(
        { input: e.target.value },
        {
          headers: {
            Authorization: `Bearer ${userAuth || userJwt}`
          }
        }
      )
        .then((response) => {
          if (response.data.length) {
            setResults(
              response.data
                .slice(0, 10)
                .map((music) => ({ ...music, thumbnail: music.image }))
            );
          } else {
            setResults([]);
            toast.info("No results.");
          }
        })
        .catch(() => {
          setResults([]);
          toast.error("Something went wrong, please try again.");
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [userJwt, userAuth]
  );

  const debouncedSearch = useMemo(
    () => _.debounce(handleSearch, 1000),
    [handleSearch]
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const resetInputValue = (): void => {
    if (!inputRef.current) {
      return;
    }

    setResults([]);
    setResults2([]);
    setResultToShow(undefined);
    setShowClearIcon(false);
    inputRef.current.value = "";
  };

  const getYoutubeResults = useCallback(
    async (title: string): Promise<ProxyResult[]> =>
      new Promise<ProxyResult[]>((resolve, reject) => {
        youtubeClient.then((yt) => {
          yt.search(title, {
            type: "video"
          })
            .then((response) => {
              const allProxyResults = (response.results ?? [])
                // .filter((video) => video.type === "Video")
                .map((video) => {
                  const youtubeId = (video as CompactVideo).id;
                  const title = (video as CompactVideo).title?.text;
                  const thumbnail = (video as CompactVideo).thumbnails?.[0]
                    ?.url;
                  const viewCount =
                    (video as CompactVideo).view_count?.text || "";
                  const duration =
                    (video as CompactVideo).duration?.seconds || Infinity;
                  const [views] = viewCount.split(" ");

                  return {
                    viewCountNumber: Number((views ?? "").replaceAll(",", "")),
                    youtubeId,
                    title,
                    duration,
                    thumbnail
                  };
                })
                .filter(
                  (video) => video.youtubeId && video.title && video.thumbnail
                );

              resolve(allProxyResults);
            })
            .catch(() => {
              toast.error("Something went wrong, please try again.");

              reject(new Error());
            });
        });
      }),

    []
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleAddV1 = useCallback(
    (songResult: SearchMusicResult): (() => Promise<void>) =>
      async (): Promise<void> => {
        const { title } = songResult;

        if (!title) {
          return;
        }

        await getYoutubeResults(title).then((youtubeResults: ProxyResult[]) => {
          const under7MinVideo = youtubeResults
            // .sort((a, b) => (a.viewCountNumber > b.viewCountNumber ? -1 : 1))
            .find(
              (video) => video.duration < 60 * 7 //mins
            );

          if (under7MinVideo?.youtubeId) {
            handleAdd({
              youtubeId: under7MinVideo.youtubeId,
              title: under7MinVideo.title,
              thumbnail: under7MinVideo.thumbnail ?? ""
            });
          } else {
            toast.error("Song not found on youtube.");
          }
        });
      },
    [getYoutubeResults, handleAdd]
  );

  const handleAddV2 = useCallback(
    (songResult: SearchMusicResult): (() => Promise<void>) =>
      async (): Promise<void> => {
        const { title } = songResult;

        if (!title) {
          return;
        }

        await getYoutubeResults(title).then((youtubeResults: ProxyResult[]) => {
          setResults2(
            youtubeResults
              // .sort((a, b) => (a.viewCountNumber > b.viewCountNumber ? -1 : 1))
              .slice(0, 4)
          );
          setResultToShow(songResult);
        });
      },
    [getYoutubeResults]
  );

  const handleAddV2Add = useCallback(
    (songResult: ProxyResult): (() => Promise<void>) =>
      async (): Promise<void> => {
        await handleAdd({
          youtubeId: songResult.youtubeId,
          title: songResult.title,
          thumbnail: songResult.thumbnail ?? ""
        });
      },
    [handleAdd]
  );

  const handleAddSubmit = useMemo(() => {
    if (version === "V2") {
      return handleAddV2;
    }

    return handleAddV1;
  }, [version, handleAddV1, handleAddV2]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setFocused(false);
      }}
    >
      <div className="searchBar">
        {isAdminPage && !focused && (
          <MenuIcon
            className="searchBar-menu"
            onClick={handleSidebarMobileToggle}
          />
        )}

        <div className="searchBar-wrap">
          <div className="searchBar-field">
            <div className="searchBar-field__search-icon">
              <SearchIcon />
            </div>
            <InputBase
              className="searchBar-field__input"
              placeholder="Search…"
              inputRef={inputRef}
              onFocus={() => {
                handleFocus();
              }}
              onClick={() => {
                handleFocus();
              }}
              onChange={(e) => {
                setShowClearIcon(e.target.value.length > 0);
                debouncedSearch(e);
              }}
            />
            {showClearIcon && (
              <div
                onClick={resetInputValue}
                className="searchBar-field__clear-icon"
              >
                <ClearIcon />
              </div>
            )}
          </div>
          <div
            className={classNames("searchBar-results", {
              "searchBar-results__open": focused,
              "searchBar-results__open-full": results.length > 0 && focused
            })}
          >
            {loading ? (
              <div className="searchBar-results__loading">
                <CircularProgress />
              </div>
            ) : results.length ? (
              <div className="searchBar-results__list">
                {resultToShow ? (
                  <>
                    <SongItem
                      className="searchBar-search2__song"
                      song={{
                        title: resultToShow.title ?? "",
                        thumbnail: resultToShow.image ?? ""
                      }}
                      ConfigComponent={
                        <Button
                          className="searchBar-search2__back"
                          variant="outlined"
                          size="small"
                          startIcon={<ArrowBackIosIcon />}
                          onClick={() => {
                            setResultToShow(undefined);
                            setResults2([]);
                          }}
                        >
                          Back
                        </Button>
                      }
                    />
                    <Divider className="searchBar-search2__divider" />
                  </>
                ) : null}

                {results2.length
                  ? results2.map((result, index) => (
                      <SongResult
                        songResult={{
                          title: result.title,
                          thumbnail: result.thumbnail ?? "",
                          youtubeId: result.youtubeId
                        }}
                        songViews={result.viewCountNumber}
                        key={index}
                        handleAddClick={handleAddV2Add(result)}
                        buttonText="add"
                      />
                    ))
                  : results.map((result, index) => (
                      <SongResult
                        songResult={{
                          title: result.title ?? "",
                          thumbnail: result.image ?? ""
                        }}
                        key={index}
                        handleAddClick={handleAddSubmit(result)}
                        buttonText="add"
                      />
                    ))}
              </div>
            ) : (
              <div className="searchBar-results__empty">Search for songs</div>
            )}
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
};
