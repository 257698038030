import { type FC } from "react";
import { Link } from "react-router-dom";

import "./styles.scss";
import { Typography } from "@mui/material";

export const Footer: FC = () => {
  return (
    <div className="footer">
      <div className="footer-content">
        <Typography className="footer-rights">
          All rights reserved © 2024
        </Typography>
        <Link to="/terms-of-use">Terms of use</Link>
        <Link to="/privacy-policy">Privacy Policy</Link>
        <Link to="/data-deletion">Data deletion</Link>
      </div>
    </div>
  );
};
