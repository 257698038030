import {
  combineReducers,
  configureStore,
  type Middleware
} from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import { authReducer } from "./slices/auth";
import { authUserReducer } from "./slices/authUser";
import { songsReducer } from "./slices/songs";
import { fallbackSongsReducer } from "./slices/fallbackSongs";
import { configReducer } from "./slices/config";
import { setToLocalStorage } from "../utils/localStorage";
import { toast } from "react-toastify";
import { votedSongsReducer } from "./slices/votedSongs";
import { usersReducer } from "./slices/users";
import { usersPageReducer } from "./slices/usersPage";

export type AppState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

const authTokeMiddleware: Middleware = () => (next) => (action) => {
  if (action.type.endsWith("auth/login")) {
    setToLocalStorage("userJwt", action.payload);
  } else if (action.type.endsWith("auth/logout")) {
    setToLocalStorage("userJwt", null);

    toast.error("Session expired, please login again.");
  }
  if (action.type.endsWith("authUser/set")) {
    setToLocalStorage("userAuthJwt", action.payload);
  }

  return next(action);
};

const rootReducer = combineReducers({
  auth: authReducer,
  authUser: authUserReducer,
  songs: songsReducer,
  fallbackSongs: fallbackSongsReducer,
  config: configReducer,
  votedSongs: votedSongsReducer,
  users: usersReducer,
  usersPage: usersPageReducer
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: [authTokeMiddleware]
});

export const useTypedSelector: TypedUseSelectorHook<AppState> = useSelector;
