import { useCallback, useEffect, useRef } from "react";
import type { FC } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import {
  add,
  remove,
  set,
  setLoading,
  updateVotes,
  update,
  setIsPausePlaying
} from "../redux/slices/songs";
import { SignalRContext } from "./EventsProvider";
import { useTypedSelector } from "../redux/store";
import { getAllSongs } from "../utils/getAll";

interface Props {
  children: JSX.Element;
}

export const VideoListEventProvider: FC<Props> = ({ children }) => {
  const dispatch = useDispatch();

  const isPausePlaying = useTypedSelector(
    (state) => state.songs.isPausePlaying
  );

  const timeoutRef = useRef<NodeJS.Timeout>();

  const _getAllSongs = useCallback(() => {
    getAllSongs(
      (res) => {
        dispatch(set(res?.data?.results ?? []));
      },
      () => {
        dispatch(setLoading(false));
      }
    );
  }, [dispatch]);

  SignalRContext.useSignalREffect(
    "PlayNewVideo",
    () => {
      _getAllSongs();
    },
    [_getAllSongs]
  );

  SignalRContext.useSignalREffect(
    "UpdateVideoVotes",
    (message) => {
      dispatch(updateVotes({ id: message.id, votes: message.votes }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (isPausePlaying) {
      timeoutRef.current = setTimeout(() => {
        dispatch(setIsPausePlaying(false));

        toast.info("TV Mode is not active", {
          toastId: "tvMode-not-active"
        });
      }, 10 * 1000);
    }
  }, [isPausePlaying, dispatch]);

  SignalRContext.useSignalREffect(
    "UpdateVideoStatus",
    (song) => {
      dispatch(update(song));

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    },
    [dispatch]
  );

  SignalRContext.useSignalREffect(
    "SendNewVideo",
    (message) => {
      dispatch(add({ ...message }));
    },
    [dispatch]
  );

  SignalRContext.useSignalREffect(
    "DeleteVideo",
    (message) => {
      dispatch(remove(message.id));
    },
    [dispatch]
  );

  useEffect(() => {
    _getAllSongs();
  }, [_getAllSongs]);

  return children;
};
