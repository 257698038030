/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AddVideoRequest,
  AdminUpdateStatusRequest,
  GlobalMessage,
  PlayVideoRequest,
  ProblemDetails,
  Video,
  VideoPaginatedResult,
  VideoStatusRequest,
  VideoVotePaginatedResult,
  VoteVideoRequest
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Playlist<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags PlayList
   * @name AddvideoCreate
   * @summary Adds a new video to playlist.
   * @request POST:/playlist/addvideo
   * @secure
   */
  addvideoCreate = (data: AddVideoRequest, params: RequestParams = {}) =>
    this.request<Video, string | ProblemDetails>({
      path: `/playlist/addvideo`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags PlayList
   * @name PlayvideoPartialUpdate
   * @summary Updates a video with current duration and LastPlayTime and creates event that will notify all listeners that a new video is added to playlist. It will also delete last played video if any.
   * @request PATCH:/playlist/playvideo
   * @secure
   */
  playvideoPartialUpdate = (
    data: PlayVideoRequest,
    params: RequestParams = {}
  ) =>
    this.request<Video, ProblemDetails | string>({
      path: `/playlist/playvideo`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags PlayList
   * @name GetallvideosList
   * @summary Retrieves an list of videos that are in playlist. Playing video will be the first from the list
   * @request GET:/playlist/getallvideos
   * @secure
   */
  getallvideosList = (
    query?: {
      /**
       * Offset(number of rows to be skipped from the start) of the total matching records.
       * @format int32
       * @min 0
       * @max 2147483647
       * @example 0
       */
      offset?: number;
      /**
       * Limit(number of rows to be taken) of the total matching records.
       * @format int32
       * @min 1
       * @max 100
       * @example 100
       */
      limit?: number;
    },
    params: RequestParams = {}
  ) =>
    this.request<VideoPaginatedResult, ProblemDetails>({
      path: `/playlist/getallvideos`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags PlayList
   * @name GetalluservideovotesList
   * @request GET:/playlist/getalluservideovotes
   * @secure
   */
  getalluservideovotesList = (
    query?: {
      /**
       * @format int32
       * @min 0
       * @max 2147483647
       */
      offset?: number;
      /**
       * @format int32
       * @min 1
       * @max 100
       */
      limit?: number;
    },
    params: RequestParams = {}
  ) =>
    this.request<VideoVotePaginatedResult, ProblemDetails>({
      path: `/playlist/getalluservideovotes`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags PlayList
   * @name SkipvideoList
   * @summary Notify clients that video is skipped.
   * @request GET:/playlist/skipvideo
   * @secure
   */
  skipvideoList = (params: RequestParams = {}) =>
    this.request<string, string>({
      path: `/playlist/skipvideo`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags PlayList
   * @name UpdatevideostatusPartialUpdate
   * @summary Updates a video with current play/pause status and creates event that will notify all clients about that status.
   * @request PATCH:/playlist/updatevideostatus
   * @secure
   */
  updatevideostatusPartialUpdate = (
    data: VideoStatusRequest,
    params: RequestParams = {}
  ) =>
    this.request<VideoStatusRequest, ProblemDetails>({
      path: `/playlist/updatevideostatus`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags PlayList
   * @name UpdatevideovotesPartialUpdate
   * @summary Updates a video with request number of votes and creates event that will notify all clients about that video were voted.
   * @request PATCH:/playlist/updatevideovotes
   * @secure
   */
  updatevideovotesPartialUpdate = (
    data: VoteVideoRequest,
    params: RequestParams = {}
  ) =>
    this.request<Video, ProblemDetails | string>({
      path: `/playlist/updatevideovotes`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags PlayList
   * @name DeletevideoDelete
   * @summary Deletes a video and notifies all clients about it.
   * @request DELETE:/playlist/deletevideo
   * @secure
   */
  deletevideoDelete = (
    query?: {
      id?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<Video, ProblemDetails>({
      path: `/playlist/deletevideo`,
      method: "DELETE",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags PlayList
   * @name AdminupdatevideostatusCreate
   * @summary Notify clients that video is played or paused by administrator.
   * @request POST:/playlist/adminupdatevideostatus
   * @secure
   */
  adminupdatevideostatusCreate = (
    data: AdminUpdateStatusRequest,
    params: RequestParams = {}
  ) =>
    this.request<Video, ProblemDetails>({
      path: `/playlist/adminupdatevideostatus`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags PlayList
   * @name GlobaljsonbroadcastCreate
   * @summary Broadcasts a json to all clients of GlobalEventHub
   * @request POST:/playlist/globaljsonbroadcast
   * @secure
   */
  globaljsonbroadcastCreate = (
    data: GlobalMessage,
    params: RequestParams = {}
  ) =>
    this.request<void, ProblemDetails>({
      path: `/playlist/globaljsonbroadcast`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params
    });
}
