import { useState } from "react";
import type { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

import type { Video } from "../../../../api/generated/data-contracts";
import { SongItem } from "../../../../components/SongItem";

import SettingsIcon from "@mui/icons-material/Settings";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

interface Props {
  song: Video & {
    voted?: boolean;
    position?: number;
  };
  handleDelete?: (id: string) => Promise<void>;
  handleVote?: (id: string, value: number) => Promise<void>;
  editDialog?: {
    setEditingSong: (song: Video) => void;
  };
  dragHandleProps?: DraggableProvidedDragHandleProps | null | undefined;
}

export const SongItemRow = ({
  song,
  handleDelete,
  handleVote,
  editDialog,
  dragHandleProps
}: Props): JSX.Element => {
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingVote, setLoadingVote] = useState(false);

  return (
    <SongItem
      song={song}
      ConfigComponent={
        <>
          {handleVote && (
            <div className="songs-list__item-config__votes">
              <div className="songs-list__item-config__votes-number">
                {song.votes}
              </div>
              {loadingVote ? (
                <CircularProgress size={24} />
              ) : song.voted ? (
                <FavoriteIcon
                  onClick={() => {
                    setLoadingVote(true);

                    handleVote(song.id, -1).then(() => {
                      setLoadingVote(false);
                    });
                  }}
                />
              ) : (
                <FavoriteBorderIcon
                  onClick={() => {
                    setLoadingVote(true);

                    handleVote(song.id, 1).then(() => {
                      setLoadingVote(false);
                    });
                  }}
                />
              )}
            </div>
          )}

          {dragHandleProps && (
            <div {...dragHandleProps}>
              <DragIndicatorIcon />
            </div>
          )}

          {editDialog && (
            <div className="songs-list__item-config__edit">
              <SettingsIcon
                onClick={() => {
                  editDialog.setEditingSong(song);
                }}
              />
            </div>
          )}

          {handleDelete && (
            <div
              className="songs-list__item-config__delete"
              onClick={() => {
                setLoadingDelete(true);

                handleDelete(song.id).then(() => {
                  setLoadingDelete(false);
                });
              }}
            >
              {loadingDelete ? <CircularProgress size={24} /> : <CloseIcon />}
            </div>
          )}
        </>
      }
    />
  );
};
