import type { FC } from "react";

import type { Video } from "../../../../api/generated/data-contracts";
import { decodeHtmlEntities } from "../../../../utils/htmlEntities";

import { ProgressBar } from "./ProgressBar";
import { Controls } from "./Controls";

import "./styles.scss";

interface Props {
  song: Video;
}

export const CurrentSong: FC<Props> = ({ song }) => {
  const { title, thumbnail } = song;

  return (
    <div className="current-song">
      <img src={thumbnail} alt={decodeHtmlEntities(title)} />

      <div className="current-song__info">
        <span className="current-song__title">{decodeHtmlEntities(title)}</span>

        <Controls song={song} />

        <ProgressBar song={song} />
      </div>
    </div>
  );
};
