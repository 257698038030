import type { Video } from "../../../../api/generated/data-contracts";

const fixISODate = (date: string): string => {
  return date.endsWith("Z") ? date : date + "Z";
};

export const getDurationInfo = (
  song: Video
): {
  currentDuration: number;
  totalDuration: number;
} => {
  const { duration, lastPausePlayDuration, lastPausePlayTime, isPaused } = song;

  const totalDuration = duration ?? 0;
  const _lastPausePlayDuration = lastPausePlayDuration ?? 0;
  const _lastPausePlayTime = new Date(
    fixISODate(lastPausePlayTime ?? new Date().toISOString())
  ).getTime();

  const secondsFromLastPausePlay =
    (new Date().getTime() - _lastPausePlayTime) / 1000;

  const estimatedDuration =
    _lastPausePlayDuration + (isPaused ? 0 : secondsFromLastPausePlay);

  const currentDuration = Math.min(totalDuration, estimatedDuration);

  return {
    currentDuration,
    totalDuration
  };
};
