import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction, Draft } from "@reduxjs/toolkit";

interface ConfigState {
  isSidebarMobileOpen: boolean;
  anotherTestKey: number;
}

const initialState: ConfigState = {
  isSidebarMobileOpen: false,
  anotherTestKey: 123
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setKey: <T extends keyof ConfigState>(
      state: Draft<ConfigState>,
      action: PayloadAction<{
        key: T;
        value: ConfigState[T];
      }>
    ) => {
      state[action.payload.key] = action.payload.value;
    }
  }
});

export const { setKey } = configSlice.actions;

export const configReducer = configSlice.reducer;
