/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AddVideoRequest,
  FallbackVideo,
  FallbackVideoPaginatedResult,
  ProblemDetails,
  UpdateFallbackVideoRequest
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Fallbackplaylist<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags FallbackPlaylist
   * @name AddvideoCreate
   * @summary Adds a new video to FallbackPlaylist.
   * @request POST:/fallbackplaylist/addvideo
   * @secure
   */
  addvideoCreate = (data: AddVideoRequest, params: RequestParams = {}) =>
    this.request<FallbackVideo, string | ProblemDetails>({
      path: `/fallbackplaylist/addvideo`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags FallbackPlaylist
   * @name DeletevideoDelete
   * @summary Deletes a video from fallback playlist.
   * @request DELETE:/fallbackplaylist/deletevideo
   * @secure
   */
  deletevideoDelete = (
    query?: {
      id?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<FallbackVideo, ProblemDetails>({
      path: `/fallbackplaylist/deletevideo`,
      method: "DELETE",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags FallbackPlaylist
   * @name GetallvideosList
   * @summary Retrieves an list of videos that are in fallback playlist. Videos are ordered by position
   * @request GET:/fallbackplaylist/getallvideos
   * @secure
   */
  getallvideosList = (
    query?: {
      /**
       * Offset(number of rows to be skipped from the start) of the total matching records.
       * @format int32
       * @min 0
       * @max 2147483647
       * @example 0
       */
      offset?: number;
      /**
       * Limit(number of rows to be taken) of the total matching records.
       * @format int32
       * @min 1
       * @max 100
       * @example 100
       */
      limit?: number;
    },
    params: RequestParams = {}
  ) =>
    this.request<FallbackVideoPaginatedResult, ProblemDetails>({
      path: `/fallbackplaylist/getallvideos`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags FallbackPlaylist
   * @name UpdatevideopositionPartialUpdate
   * @summary Update the position of a video
   * @request PATCH:/fallbackplaylist/updatevideoposition
   * @secure
   */
  updatevideopositionPartialUpdate = (
    data: UpdateFallbackVideoRequest,
    params: RequestParams = {}
  ) =>
    this.request<FallbackVideo, string | ProblemDetails>({
      path: `/fallbackplaylist/updatevideoposition`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags FallbackPlaylist
   * @name UpdatevideospositiontransactionPartialUpdate
   * @summary Sorts and updates all videos positions
   * @request PATCH:/fallbackplaylist/updatevideospositiontransaction
   * @secure
   */
  updatevideospositiontransactionPartialUpdate = (params: RequestParams = {}) =>
    this.request<void, string | ProblemDetails>({
      path: `/fallbackplaylist/updatevideospositiontransaction`,
      method: "PATCH",
      secure: true,
      ...params
    });
}
