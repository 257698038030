import type { FC } from "react";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

import { PrivateRoutes } from "./router/PrivateRoutes";
import { VideoListEventProvider } from "./providers/VideoListEventProvider";
import { ApiService, PlaylistService } from "./api";
import { AdminPage } from "./pages/Admin";
import { LoginPage } from "./pages/Login";
import { TvModeV2 } from "./pages/TvModeV2";
import { VotePage } from "./pages/Vote";
import { EventsProvider } from "./providers/EventsProvider";
import { login, logout } from "./redux/slices/auth";
import { getFromLocalStorage } from "./utils/localStorage";
import * as Str from "./utils/reader/string";
import { AllSongsProvider } from "./providers/AllSongsProvider";
import { useAuth0 } from "@auth0/auth0-react";
import { set as setUserAuth } from "./redux/slices/authUser";
import { set as setVotedSongs } from "./redux/slices/votedSongs";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { DataDeletion } from "./pages/DataDeletion";
import { TermsOfUse } from "./pages/TermsOfUse";

enum LoadingState {
  loading = "loading",
  loaded = "loaded"
}

export const App: FC = () => {
  const dispatch = useDispatch();
  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.loading
  );
  const user = useAuth0();

  useEffect(() => {
    if (user.isAuthenticated) {
      user
        .getAccessTokenSilently({
          authorizationParams: {
            scope: "openid profile email"
          }
        })
        .then((token) => {
          dispatch(setUserAuth(token));

          PlaylistService.getalluservideovotesList(
            {},
            { headers: { Authorization: `Bearer ${token}` } }
          ).then((res) => {
            dispatch(
              setVotedSongs(res.data.results?.map((x) => x.videoId) ?? [])
            );
          });
        })
        .catch(() => {
          if (user.isAuthenticated) {
            user.logout();
          }
        });
    }
  }, [user, dispatch]);

  useEffect(() => {
    const userJwt = Str.read(getFromLocalStorage("userJwt"));

    if (!userJwt || userJwt.length === 0) {
      setLoadingState(LoadingState.loaded);

      return;
    }

    ApiService.cacheAllList(
      {},
      {
        headers: {
          Authorization: `Bearer ${userJwt}`
        }
      }
    )
      .then((res) => {
        if (res.status === 200) {
          dispatch(login(userJwt));
        } else {
          dispatch(logout());
        }
      })
      .catch(() => {
        dispatch(logout());
      })
      .finally(() => {
        setLoadingState(LoadingState.loaded);
      });
  }, [dispatch]);

  return (
    <>
      {loadingState === LoadingState.loading ? (
        <div className="center-loading-block">
          <CircularProgress />
        </div>
      ) : (
        <Routes>
          <Route path="/login" element={<LoginPage />} />

          <Route element={<EventsProvider />}>
            <Route element={<PrivateRoutes />}>
              <Route
                path="/admin/*"
                element={
                  <VideoListEventProvider>
                    <AllSongsProvider>
                      <AdminPage />
                    </AllSongsProvider>
                  </VideoListEventProvider>
                }
              />
              <Route
                path="/player"
                element={isMobile ? <Navigate to="/" /> : <TvModeV2 />}
              />
            </Route>

            <Route
              path="/vote"
              element={
                <VideoListEventProvider>
                  <VotePage />
                </VideoListEventProvider>
              }
            />

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />

            <Route path="/data-deletion" element={<DataDeletion />} />
          </Route>

          <Route path="*" index element={<Navigate to="/vote" />} />
        </Routes>
      )}
    </>
  );
};
