import { type FallbackVideo } from "../../../api/generated/data-contracts";

export const getUpdatedPosition = (
  songs: FallbackVideo[],
  oldIndex: number,
  newIndex: number
): number | null => {
  const isMovedDown = newIndex > oldIndex ? 1 : 0;

  const prevItem = songs[newIndex - 1 + isMovedDown];
  const nextItem = songs[newIndex + isMovedDown];

  const prevItemPosition = prevItem?.position ?? 0;
  const nextItemPosition = nextItem?.position ?? 0;

  if (prevItemPosition + 1 === nextItemPosition) {
    return null;
  }

  const isMovedToLast = newIndex === songs.length - 1;
  const lastItem = songs[songs.length - 1];
  const lastItemPosition = lastItem?.position ?? 0;

  if (isMovedToLast) {
    return lastItemPosition + 10000;
  }

  const newPosition =
    prevItemPosition + (nextItemPosition - prevItemPosition) / 2;

  return newPosition;
};

export const reorder = <T>(
  list: T[],
  startIndex: number,
  endIndex: number
): T[] => {
  const result = [...list];

  const [removed] = result.splice(startIndex, 1);

  if (removed) {
    result.splice(endIndex, 0, removed);
  }

  return result;
};
