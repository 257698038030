import { useState, useEffect } from "react";

const doc = window.document;
const docEl = doc.documentElement;

const requestFullScreen =
  docEl.requestFullscreen ||
  // @ts-expect-error Property 'mozRequestFullScreen' does not exist on type 'Element'
  docEl.mozRequestFullScreen ||
  // @ts-expect-error Property 'webkitRequestFullScreen' does not exist on type 'Element'
  docEl.webkitRequestFullScreen ||
  // @ts-expect-error Property 'msRequestFullscreen' does not exist on type 'Element'
  docEl.msRequestFullscreen;

const cancelFullScreen =
  doc.exitFullscreen ||
  // @ts-expect-error Property 'mozCancelFullScreen' does not exist on type 'Document'
  doc.mozCancelFullScreen ||
  // @ts-expect-error Property 'webkitExitFullscreen' does not exist on type 'Document'
  doc.webkitExitFullscreen ||
  // @ts-expect-error Property 'msExitFullscreen' does not exist on type 'Document'
  doc.msExitFullscreen;

const currentlyInFullScreen = (): Element | null =>
  doc.fullscreenElement ??
  // @ts-expect-error Property 'mozFullScreenElement' does not exist on type 'Document'
  doc.mozFullScreenElement ??
  // @ts-expect-error Property 'webkitFullscreenElement' does not exist on type 'Document'
  doc.webkitFullscreenElement ??
  // @ts-expect-error Property 'msFullscreenElement' does not exist on type 'Document'
  doc.msFullscreenElement;

export const useFullScreenHandler = (): {
  enterFullScreen: () => void;
  exitFullScreen: () => void;
  inFullScreen: Element | null;
} => {
  const [inFullScreen, setInFullScreen] = useState(currentlyInFullScreen());

  useEffect(() => {
    document.onfullscreenchange = () => {
      setInFullScreen(currentlyInFullScreen());
    };

    // @ts-expect-error Type 'undefined' is not assignable to type '((this: Document, ev: Event) => any) | null'.
    return () => (document.onfullscreenchange = undefined);
  }, []);

  const enterFullScreen = (): void => {
    if (inFullScreen) {
      return;
    }
    requestFullScreen.call(docEl);
  };

  const exitFullScreen = (): void => {
    if (!inFullScreen) {
      return;
    }
    cancelFullScreen.call(doc);
  };

  return { enterFullScreen, exitFullScreen, inFullScreen };
};
