import { type FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import type { AxiosResponse } from "axios";
import type { OnDragEndResponder } from "react-beautiful-dnd";
import CircularProgress from "@mui/material/CircularProgress";

import type {
  FallbackVideo,
  FallbackVideoPaginatedResult,
  SearchVideoResult
} from "../../../api/generated/data-contracts";
import { useTypedSelector } from "../../../redux/store";
import { FallbackPlaylistService } from "../../../api";
import { logout } from "../../../redux/slices/auth";
import { SearchBar } from "../../../components/SearchBar";
import { SongsList } from "../components/SongsList";
import { isNullish } from "../../../utils/value";
import { getAllFallbackSongs } from "../../../utils/getAll";
import {
  addSong,
  removeSong,
  setSongs,
  updateSong
} from "../../../redux/slices/fallbackSongs";

import { getUpdatedPosition, reorder } from "./utils";

import "./styles.scss";

export const Fallback: FC = () => {
  const dispatch = useDispatch();

  const userJwt = useTypedSelector((state) => state.auth.value);
  const songs = useTypedSelector((state) => state.fallbackSongs.value);
  const loadingSongs = useTypedSelector((state) => state.fallbackSongs.loading);

  const addItemToFallback = useCallback(
    async (songResult: SearchVideoResult): Promise<void> => {
      await FallbackPlaylistService.addvideoCreate(
        {
          youtubeId: songResult.youtubeId,
          title: songResult.title,
          thumbnail: songResult.thumbnail
        },
        {
          headers: {
            Authorization: `Bearer ${userJwt}`
          }
        }
      )
        .then((res) => {
          if (res.status === 200) {
            dispatch(addSong(res.data));
            toast.success("Successfully added!");
          } else {
            toast.error("Something went wrong, please try again.");
          }
        })
        .catch((res) => {
          if (res.response?.status === 400) {
            for (const property in res.response?.data.errors) {
              res.response?.data.errors[property].forEach((eMessage: string) =>
                toast.error(eMessage)
              );
            }
          } else if (res.response?.status === 401) {
            dispatch(logout());
          } else {
            toast.error("Something went wrong, please try again.");
          }
        });
    },
    [dispatch, userJwt]
  );

  const deleteSongFromFallBack = useCallback(
    async (id: string): Promise<void> =>
      FallbackPlaylistService.deletevideoDelete(
        {
          id
        },
        {
          headers: {
            Authorization: `Bearer ${userJwt}`
          }
        }
      )
        .then((res) => {
          if (res.status === 200) {
            dispatch(removeSong(res.data.id));
            toast.success("Successfully deleted.");
          } else {
            toast.error("Something went wrong, please try again.");
          }
        })
        .catch((res) => {
          if (res.response?.status === 401) {
            dispatch(logout());
          } else {
            toast.error("Something went wrong, please try again");
          }
        }),
    [dispatch, userJwt]
  );

  const updateItemPositionRequest = useCallback(
    async (id: string, position: number): Promise<void> =>
      FallbackPlaylistService.updatevideopositionPartialUpdate(
        {
          id,
          position
        },
        {
          headers: {
            Authorization: `Bearer ${userJwt}`
          }
        }
      )
        .then((res) => {
          if (res.status === 200) {
            dispatch(updateSong(res.data));
          } else {
            toast.error("Something went wrong, please try again.");
          }
        })
        .catch((res) => {
          if (res.response?.status === 401) {
            dispatch(logout());
          } else {
            toast.error("Something went wrong, please try again.");
          }
        }),
    [dispatch, userJwt]
  );

  const refreshPositions = useCallback(
    async (): Promise<void> =>
      FallbackPlaylistService.updatevideospositiontransactionPartialUpdate({
        headers: {
          Authorization: `Bearer ${userJwt}`
        }
      })
        .then((res) => {
          if (res.status !== 204) {
            toast.error("Something went wrong, please try again.");
          }
        })
        .catch((res) => {
          if (res.response?.status === 401) {
            dispatch(logout());
          } else {
            toast.error("Something went wrong, please try again.");
          }
        }),
    [dispatch, userJwt]
  );

  const getSongs = async (): Promise<FallbackVideo[]> =>
    new Promise<FallbackVideo[]>((resolve, reject) => {
      const handleSet = (
        res: AxiosResponse<FallbackVideoPaginatedResult, any>
      ): void => {
        resolve(res?.data?.results ?? []);
      };
      const afterCatch = (): void => {
        reject(new Error("Something went wrong, please try again."));
      };
      const handle401 = (): void => {
        dispatch(logout());
      };

      getAllFallbackSongs(userJwt, handleSet, afterCatch, handle401);
    });

  const updateItemPosition = useCallback(
    (
      songs: FallbackVideo[],
      currentItemId: string,
      newPosition: number
    ): void => {
      toast.promise(
        updateItemPositionRequest(currentItemId, Math.round(newPosition)).catch(
          () => {
            dispatch(setSongs(songs));

            toast.error("Something went wrong, please try again.");
          }
        ),
        {
          pending: "Updating position...",
          success: "Successfully updated position.",
          error: "Something went wrong, please try again."
        }
      );
    },
    [updateItemPositionRequest, dispatch]
  );

  const onDragEnd: OnDragEndResponder = (res) => {
    const oldIndex: number = res.source.index;
    const newIndex: number | undefined = res.destination?.index;
    const currentItemId = songs[oldIndex]?.id;

    if (
      !isNullish(newIndex) &&
      !isNullish(currentItemId) &&
      oldIndex !== newIndex
    ) {
      const newPosition = getUpdatedPosition(songs, oldIndex, newIndex);

      if (isNullish(newPosition)) {
        refreshPositions()
          .then(getSongs)
          .then((songs) => {
            dispatch(setSongs(reorder(songs, oldIndex, newIndex)));

            const newPosition = getUpdatedPosition(songs, oldIndex, newIndex);

            updateItemPosition(
              songs,
              currentItemId,
              newPosition as number // because we already refreshed positions
            );
          });
      } else {
        dispatch(setSongs(reorder(songs, oldIndex, newIndex)));

        updateItemPosition(songs, currentItemId, newPosition);
      }
    }
  };

  return (
    <div className="fallback-page">
      <SearchBar handleAdd={addItemToFallback} />

      {loadingSongs ? (
        <CircularProgress className="loading" />
      ) : songs.length ? (
        <SongsList
          songs={songs}
          handleDelete={deleteSongFromFallBack}
          onDragEnd={onDragEnd}
        />
      ) : (
        <div className="fallback-page__no-items">No song added</div>
      )}
    </div>
  );
};
