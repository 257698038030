// adapted from https://github.com/sindresorhus/parse-ms.
// moved to internal function because parse-ms is now pure ESM.
const parseMs = (
  ms: number
): {
  minutes: number;
  seconds: number;
} => {
  return {
    minutes: Math.trunc(ms / 60) % 60,
    seconds: Math.trunc(ms) % 60
  };
};

// adapted from https://github.com/rafaelrinaldi/add-zero.
// moved to internal function b/c addZero is unmaintained (7+ years).
// stripped out negative sign logic since we're already doing it elsewhere.
const addZero = (value: number, digits: number = 2): string => {
  let str = value.toString();
  let size = 0;

  size = digits - str.length + 1;
  str = new Array(size).join("0").concat(str);

  return str;
};

export function formatDuration(ms: number): string {
  const unsignedMs = ms < 0 ? -ms : ms;
  const t = parseMs(unsignedMs);
  const seconds = addZero(t.seconds);

  return `${t.minutes}:${seconds}`;
}
