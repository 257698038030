import type { FC } from "react";
import { motion } from "framer-motion";
import {
  DragDropContext,
  Droppable,
  Draggable,
  type DraggingStyle,
  type NotDraggingStyle,
  type OnDragEndResponder
} from "react-beautiful-dnd";

import type { Video } from "../../../../api/generated/data-contracts";

import { SongItemRow } from "./SongItem";

import "./styles.scss";
import React from "react";

function getCustomDraggableStyle(
  style: DraggingStyle | NotDraggingStyle | undefined
): React.CSSProperties | undefined {
  if (!style) {
    return style;
  }

  if (!style.transform) {
    return style;
  }

  const axisLockY =
    "translate(0px" +
    style.transform.slice(style.transform.indexOf(","), style.transform.length);

  return {
    ...style,
    transform: axisLockY
  };
}
interface Props {
  songs: (Video & { voted?: boolean } & { position?: number })[];
  handleDelete?: (id: string) => Promise<void>;
  handleVote?: (id: string, value: number) => Promise<void>;
  canVote?: boolean;
  editDialog?: {
    setEditingSong: (song: Video) => void;
  };
  onDragEnd?: OnDragEndResponder;
}

export const SongsList: FC<Props> = ({
  songs,
  handleDelete,
  handleVote,
  editDialog,
  onDragEnd
}) => {
  return (
    <>
      {onDragEnd ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="songs-list songs-list__all"
              >
                {songs.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={getCustomDraggableStyle(
                            provided.draggableProps.style
                          )}
                          className="songs-list__item-outer-block"
                        >
                          <SongItemRow
                            song={item}
                            key={item.id}
                            handleDelete={handleDelete}
                            handleVote={handleVote}
                            editDialog={editDialog}
                            dragHandleProps={provided.dragHandleProps}
                          />
                        </div>
                      );
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <motion.div layout className="songs-list songs-list__all">
          {songs.map((item) => (
            <motion.div
              layout
              key={item.id}
              className="songs-list__item-outer-block"
            >
              <SongItemRow
                song={item}
                key={item.id}
                handleDelete={handleDelete}
                handleVote={handleVote}
                editDialog={editDialog}
              />
            </motion.div>
          ))}
        </motion.div>
      )}
    </>
  );
};
