import type { FC } from "react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

import "./styles.scss";

interface SongLikesProps {
  likes: number;
}

export const SongLikes: FC<SongLikesProps> = ({ likes }) => (
  <div className="song-likes">
    <span>{likes}</span> <FavoriteBorderIcon />
  </div>
);
