/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CacheActionResult,
  CachePaginatedResult,
  ProblemDetails
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Api<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Cache
   * @name CacheCreate
   * @request POST:/api/cache
   * @secure
   */
  cacheCreate = (data: Record<string, string>, params: RequestParams = {}) =>
    this.request<void, ProblemDetails>({
      path: `/api/cache`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * No description
   *
   * @tags Cache
   * @name CacheList
   * @request GET:/api/cache
   * @secure
   */
  cacheList = (
    query: {
      key: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<CacheActionResult, ProblemDetails>({
      path: `/api/cache`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Cache
   * @name CacheAllList
   * @request GET:/api/cache/all
   * @secure
   */
  cacheAllList = (
    query?: {
      /**
       * @format int32
       * @min 0
       */
      offset?: number;
      /**
       * @format int32
       * @min 1
       * @max 100
       */
      limit?: number;
    },
    params: RequestParams = {}
  ) =>
    this.request<CachePaginatedResult, ProblemDetails>({
      path: `/api/cache/all`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
}
