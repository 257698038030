import { useState } from "react";
import type { FC } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import { SearchBar } from "../../../components/SearchBar";
import type { PublicOutletContext } from "../../../providers/EventsProvider";
import { logout } from "../../../redux/slices/auth";
import { currentSongSelector } from "../../../redux/slices/songs";
import type { Video } from "../../../api/generated/data-contracts";
import { useTypedSelector } from "../../../redux/store";
import { PlaylistService } from "../../../api";
import { CurrentSong } from "../components/CurrentSong";
import { SongsList } from "../components/SongsList";

import { EditDialog } from "./EditDialog";

import "./styles.scss";

export const PlaylistAdminContent: FC = () => {
  const dispatch = useDispatch();

  const currentSong = useSelector(currentSongSelector);
  const loadingSongs = useTypedSelector((state) => state.songs.loading);
  const userJwt = useTypedSelector((state) => state.auth.value);

  const { addItemToPlaylist, handleVote, upcomingSongs } =
    useOutletContext<PublicOutletContext>();

  const handleDelete = async (id: string): Promise<void> =>
    new Promise<void>((resolve) => {
      PlaylistService.deletevideoDelete(
        {
          id
        },
        {
          headers: {
            Authorization: `Bearer ${userJwt}`
          }
        }
      )
        .then((res) => {
          if (res.status === 200 || res.status === 204) {
            toast.success("Successfully deleted.");
          } else if (res.status === 404) {
            toast.error("Song not found or already deleted.");
          } else {
            toast.error("Something went wrong, please try again.");
          }
        })
        .catch((res) => {
          if (res.response?.status === 401) {
            dispatch(logout());
          } else {
            toast.error("Something went wrong, please try again");
          }
        })
        .finally(() => {
          resolve();
        });
    });

  const [editingSong, setEditingSong] = useState<Video | null>(null);

  const handleClose = (): void => {
    setEditingSong(null);
  };

  return (
    <div className="playlist-page">
      <SearchBar handleAdd={addItemToPlaylist} />

      {editingSong && (
        <EditDialog
          song={editingSong}
          handleClose={handleClose}
          handleVote={handleVote}
        />
      )}

      {loadingSongs ? (
        <CircularProgress />
      ) : !currentSong && upcomingSongs.length === 0 ? (
        <div className="playlist-page__no-items">No song added</div>
      ) : (
        <>
          {currentSong && <CurrentSong song={currentSong} />}
          <SongsList
            songs={upcomingSongs}
            handleDelete={handleDelete}
            handleVote={handleVote}
            editDialog={{ setEditingSong }}
          />
        </>
      )}
    </div>
  );
};
