import { type FC } from "react";

import "./styles.scss";
import { Footer } from "../../components/Footer";

export const TermsOfUse: FC = () => {
  return (
    <>
      <div className="page-termsOfUse">
        <h1>Terms of use</h1>
        <p>
          Welcome to Verdibox, a unique platform designed to enhance your party
          experience by allowing participants to vote on songs offline. These
          Terms of Use govern your access to and use of our website and
          services. By accessing or using the service, you agree to be bound by
          these Terms.
        </p>

        <h2>1. Account Registration</h2>
        <p>
          To access certain features of Verdibox, you must register for an
          account through Auth0 using your Google or Facebook account. By
          registering, you agree to provide accurate, current, and complete
          information about yourself as prompted by our registration form and to
          maintain and promptly update your information to keep it accurate,
          current, and complete.
        </p>

        <h2>2. Privacy Policy</h2>
        <p>
          Your privacy is important to us. Our Privacy Policy explains how we
          collect, use, and protect your personal information. By using
          Verdibox, you agree to the collection and use of your information in
          accordance with our Privacy Policy.
        </p>

        <h2>3. User Conduct</h2>
        <p>
          You are responsible for all your activity in connection with the
          service. Verdibox may not be used for any illegal or unauthorized
          purposes. You agree to comply with all laws, rules, and regulations
          applicable to your use of the service.
        </p>

        <h2>4. Content</h2>
        <p>
          You retain all rights in, and are solely responsible for, the content
          you post to Verdibox. You grant Verdibox a non-exclusive, royalty-free
          license to use, reproduce, modify, adapt, publish, and display your
          content in connection with the service.
        </p>

        <h2>5. Third-party Services</h2>
        <p>
          Verdibox uses Auth0 for account registration and may allow you to link
          your account with Google or Facebook. Your use of these third-party
          services is subject to their respective terms of use and privacy
          policies.
        </p>

        <h2>6. Modifications to the Service</h2>
        <p>
          Verdibox reserves the right to modify or discontinue, temporarily or
          permanently, the service (or any part thereof) with or without notice.
          You agree that Verdibox shall not be liable to you or to any third
          party for any modification, suspension, or discontinuance of the
          service.
        </p>

        <h2>7. Termination</h2>
        <p>
          Verdibox may terminate or suspend your account and access to the
          service immediately, without prior notice or liability, for any reason
          whatsoever, including without limitation if you breach the Terms.
        </p>

        <h2>8. Governing Law</h2>
        <p>
          These Terms shall be governed and construed in accordance with the
          laws of [Your Country/State], without regard to its conflict of law
          provisions.
        </p>

        <h2>9. Changes</h2>
        <p>
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. If a revision is material, we will provide at
          least [number] days' notice prior to any new terms taking effect.
        </p>

        <p>
          By continuing to access or use our service after those revisions
          become effective, you agree to be bound by the revised terms.
        </p>
      </div>
      <Footer />
    </>
  );
};
