import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface UsersPageState {
  value: number;
}

const initialState: UsersPageState = {
  value: 1
};

export const usersPageSlice = createSlice({
  name: "usersPage",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<number>) => {
      state.value = action.payload;
    }
  }
});

export const { set } = usersPageSlice.actions;

export const usersPageReducer = usersPageSlice.reducer;
