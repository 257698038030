import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import type { FallbackVideo } from "../../api/generated/data-contracts";

export interface FallbackSongsState {
  value: FallbackVideo[];
  loading: boolean;
}

const initialState: FallbackSongsState = {
  value: [],
  loading: true
};

export const fallbackSongsSlice = createSlice({
  name: "fallbackSongs",
  initialState,
  reducers: {
    setSongsLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSongs: (state, action: PayloadAction<FallbackVideo[]>) => {
      state.value = action.payload;
      state.loading = false;
    },
    updateSong: (state, action: PayloadAction<FallbackVideo>) => {
      state.value = state.value.map((s) =>
        s.id === action.payload.id ? action.payload : s
      );
    },
    addSong: (state, action: PayloadAction<FallbackVideo>) => {
      state.value = [...state.value, action.payload];
    },
    removeSong: (state, action: PayloadAction<string>) => {
      state.value = state.value.filter((song) => song.id !== action.payload);
    }
  }
});

export const { setSongsLoading, setSongs, updateSong, addSong, removeSong } =
  fallbackSongsSlice.actions;

export const fallbackSongsReducer = fallbackSongsSlice.reducer;
