import { useEffect } from "react";
import type { FC } from "react";
import { useDispatch } from "react-redux";
import type { AxiosResponse } from "axios";

import { set, setLoading } from "../redux/slices/songs";
import { logout } from "../redux/slices/auth";
import { useTypedSelector } from "../redux/store";
import { getAllFallbackSongs, getAllSongs } from "../utils/getAll";
import type {
  FallbackVideoPaginatedResult,
  VideoPaginatedResult
} from "../api/generated/data-contracts";
import { setSongs, setSongsLoading } from "../redux/slices/fallbackSongs";

interface Props {
  children: JSX.Element;
}

export const AllSongsProvider: FC<Props> = ({ children }) => {
  const dispatch = useDispatch();

  const userJwt = useTypedSelector((state) => state.auth.value);

  useEffect(() => {
    const handleSet = (res: AxiosResponse<VideoPaginatedResult, any>): void => {
      dispatch(set(res?.data?.results ?? []));
    };
    const afterCatch = (): void => {
      dispatch(setLoading(false));
    };

    getAllSongs(handleSet, afterCatch);
  }, [dispatch]);

  useEffect(() => {
    const handleSet = (
      res: AxiosResponse<FallbackVideoPaginatedResult, any>
    ): void => {
      dispatch(setSongs(res?.data?.results ?? []));
    };
    const afterCatch = (): void => {
      dispatch(setSongsLoading(false));
    };
    const handle401 = (): void => {
      dispatch(logout());
    };

    getAllFallbackSongs(userJwt, handleSet, afterCatch, handle401);
  }, [dispatch, userJwt]);

  return children;
};
